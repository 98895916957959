<template>
  <Carousel v-if="data.length > 0" :autoplay="5000" :wrap-around="true">
          
          <slide v-for="page in data" :key="page.message">
            <div class="flex justify-between bg-white">
              <div
                class="w-full md:w-1/2 mx-auto md:mx-0 md:my-auto px-10 md:py-0 md:px-10 lg:px-10 py-7"
              >
                <p
                  class="
                    text-left
                    leading-8
                    md:leading-10
                    roboto-regular
                    text-cherryColor text-2xl
                    px-1
                    md:px-5
                    pb-3
                    md:pb-6
                    md:pl-0 md:pt-4
                  "
                >
                  {{ page.message }}
                </p>
                <div
                  v-for="(button, i) in page.buttons"
                  :key="i"
                  class="w-full md:mb-4"
                >
                  <a
                    v-if="button.externalLink"
                    :href="button.link"
                    target="_blank"
                    class="
                      button_link_message_slide
                      block
                      w-full
                      md:mr-2
                      button-250
                      bg-mustardDark
                      p-3
                      rounded
                      text-white
                    "
                  >
                    {{ button.text }}
                  </a>
                  <router-link
                    v-else
                    :to="button.link"
                    class="
                      button_link_message_slide
                      block
                      w-full
                      md:mr-2
                      button-250
                      bg-mustardDark
                      p-3
                      rounded
                      text-white
                    "
                  >
                    {{ button.text }}
                  </router-link>
                </div>
              </div>

              <div
                class="
                  hidden
                  w-full
                  md:w-1/2
                  mx-auto
                  md:mx-0 md:px-0 md:py-14
                  bg-gray
                  md:block
                "
              >
                <img
                  :src="page.images.desktop"
                  :alt="page.images.desktop"
                  class="w-full mx-auto md:my-14 lg:my-3"
                />
              </div>
            </div>
          </slide>

          <template #addons class="mt-10 hidden md:block">
            <Pagination />
          </template>

        </Carousel>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "MessageSlide",
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination
  },
  setup() {
    return {};
  },
};
</script>
<style scoped>
.h-message-slide {
  height: auto;
}

.bg-gray {
  background-color: #f9fcff;
}
#button_message_message_slide {
  font-family: "roboto-regular";
  font-size: 22px;
}
.button_link_message_slide {
  font-family: "roboto-regular";
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .h-message-slide {
    min-height: 34vh;
  }
  .card-container {
    height: 25rem;
  }
}
@media (min-width: 768px) {
  .card-container {
    height: 20rem;
  }
  .button-250 {
    width: 250px;
  }
}
.carousel__pagination-button {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 0;
    background-color: #EDEDED;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
}
.carousel__pagination-button--active {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  background-color: #DEDEDE;
  margin-top: auto;
  margin-bottom: auto;
}
.carousel__pagination{
  margin-top: 25px;
}
.carousel {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .carousel__viewport {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  }
}

@media (min-width: 768px) {
  .carousel__viewport {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  }
}

@media (max-width: 768px) {
  .carousel__viewport {
    background: #FFFFFF;
    box-shadow: 0px !important;;
  }
  .carousel {
    background: #FFFFFF;
  }
  .carousel__pagination {
    margin-top: 10px;
  }
  .carousel__slide {
    border-top-width: 1.5px;
  }
}

</style>