<template>
  <div
    v-if="cardElement.status"
    class="
      flex
      w-auto
      md:flex-col
      items-center
      md:w-full 
      lg:mx-auto
      lg:h-72
      lg:py-6
      h-32
      md:h-64
      md:py-2
      mx-1
      px-4 
      py-4 
      border
      rounded
      bg-white
      card-shadow
      cursor-pointer
    "
  >
    <div class="img-box w-auto md:mx-auto md:mr-0">
        <img v-if="cardElement.image!=''" :src="cardElement.image" alt="image_card" :key="cardElement.cardElement" />
    </div>
    <div v-if="cardElement.title!=''" class="flex w-full items-center py-4 pr-1 md:pt-0 md:h-10 md:block md:text-center md:my-3">
      <p class="leading-5 font-medium simple-card-title card-title">{{ cardElement.title }} </p>
    </div>
    <div class="simple-card-description grid w-full justify-items-end md:justify-items-center py-6 md:py-0 ">
      <div v-if="cardElement.mainText!=''" v-html="cardElement.mainText" class="simple-card-main md:my-auto text-cherryColor"></div>
      <div v-if="cardElement.description!=''" class="text-sm md:text-md lg:text-lg font-thin md:font-light card-description" v-html="cardElement.description"></div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, onMounted, ref, toRef } from 'vue';

export default {
  name: "SimpleCard",
  props: {
    data: {
      type: Object,
      defaul: { status: true, image: "", title: "", mainText: "", description: "" },
    },
  },
  setup(props) {

    let cardElement = ref({ status: true, image: "", title: "", mainText: "", description: "" });
    let pagesList = toRef(props, 'data');

    onBeforeMount(async ()=>{
      let data  = pagesList.value;
      data = await assing_image_card(data);
      cardElement.value = data;
    });

    async function assing_image_card(card){
      let imageImport = await import(`@/assets/${card.image}`);
      card.image = imageImport.default;
      return card;
    }

    return { cardElement }
  }
};
</script>
<style scoped>
.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.image{
  height: max-content;
  width: auto;
  margin: auto;
}

.card-simple-card-main,
.simple-card-main, 
.card-title,
.card-description, 
.simple-card-text-small,
.simple-card-title {
  font-family: "roboto-regular";
}

.card-title {
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-size: 18px;
  color: #650A35;
}

.card-simple-card-main {
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #650A35;
}

.card-description, .simple-card-text-small {
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #857B70;
}

.img-box {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .card-title {
    text-align:left;
  }
  .img-box {
    width: 50%;
    margin-right: 10px;
    height: auto;
  }
 
}

</style>