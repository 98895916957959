<template>
  <!--Admission Request Accepted-->
  <div :class="{'bg-container': admissionStatus != 'aceptada', 'bg-gray-container': admissionStatus == 'aceptada'}" class=" mx-auto">
    <Loading :isLoading="isLoading" :key="isLoading"/>

    <div v-show="admissionStatus == 'aceptada'">
      <div
        class="w-full h-full px-5 md:px-5 lg:px-10 pt-2 md:pt-5 lg:max-h-screen mb-5"
      >
        <h1 class="primary-text text-xl md:text-2xl text-thin mt-2 mb-5 md:mb-7">
          ¡Hola, {{ clientName }}!
        </h1>

        <!--Ads-->
        <div class="w-full max-w-full mx-auto mb-14">
          <p v-show="adsList.length > 0" class="text-black font-normal mb-1">Notificaciones</p>

          <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-8 gap-4 md:gap-4 lg:gap-7">
            <AdsComponent v-for="(ad, key) in adsList" :key="key"  :view_class_view="{rectangle: ' w-full '}">
                <template v-slot:content>
                  <router-link :to="ad.route">
                    <p v-html="ad.message"></p>
                  </router-link>
                </template>
            </AdsComponent>
          </div>
        </div>
        <!--End Ads-->

        <!--Cards-->
        <div
          class="
            card-container
            md:w-full
            overflow-x-scroll
            md:overflow-auto
            h-96
            md:h-96
            grid
            md:grid-cols-3
            gap-1
            md:gap-3
            lg:gap-10
            hide-scroll
          "
          v-if="simpleCardArray.length > 0 && hasBenefitsAvailables"
        >
          <simple-card
            v-for="(row, key) in simpleCardArray"
            :key="key"
            :data="row"
            
          />
          <!-- Se comenta este evento click hasta que se tenga el detalle de pagos de ahorro -->
          <!-- @click="navigate(row.route)"  -->
        </div>
        <!--End Cards-->
      </div>

      <!--Carousel-->
      <message-slide :data="messageSlideArray" v-if="messageSlideArray.length > 0"/>
      <!--End Carousel-->
    </div>
  </div>
  <div v-if="admissionStatus == 'pendiente_de_revision' || admissionStatus == 'en_revision'" class="mt-2">

      <div class="w-full h-full px-5 md:px-5 lg:px-10 pt-2 md:pt-5 lg:max-h-screen mb-5">

        <h1 class="primary-text text-xl md:text-2xl text-thin mt-2 mb-5 md:mb-7">
          ¡Hola, {{ clientName }}!
        </h1>

      </div>

      <message-slide :data="messageSlideArray" v-if="messageSlideArray.length > 0"/>
    <!-- <div>
      <div style="display: flex; justify-content: center">
        <div>
          <img src="@/assets/Cuenta/welcome.png" alt="" />
        </div>
      </div>
      <div style="display: flex; justify-content: center; display: none">
        <div style="font-family: Roboto" class="circled-number">
          <div>
            <center>
              <p class="title" style="text-align: center; font-size: 40px">
                $0.00
              </p>
              <br />
              <p
                style="
                  color: #696969;
                  margin-top: -30px;
                  font-size: 23px;
                  font-weight: 300;
                "
                class="subTitle"
              >
                Total Ahorrado
              </p>
            </center>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          margin-top: 20px;
          display: none;
        "
      >
        <div>
          <p
            class="subTitle"
            style="
              text-align: center;
              font-size: 20px;
              color: #696969;
              margin-bottom: 0px;
              font-weight: 300;
            "
          >
            Aportación Quincenal
          </p>
          <p
            class="subTitle"
            style="
              text-align: center;
              font-size: 20px;
              color: #696969;
              font-weight: 700;
            "
          >
            $0.00
          </p>
        </div>
      </div>
      <div>
        <div class="slideshow-container">
          <div
            class="mySlides flex"
            v-if="
              (!hasPayment && admissionStatus == 'pendiente_de_revision') ||
              admissionStatus == 'en_revision' ||
              admissionStatus != 'aceptada'
            "
          >
            <div class="slide-container">
              <br />
              <p class="titleCard" style="color: white">
                Solicitud en revisión
              </p>
              <p class="descriptionCard" style="margin-top: 15px; color: white">
                Pronto será notificado cuando su solicitud sea aceptada o
                rechazada
              </p>
              <br />
            </div>
          </div>

          <div
            class="mySlides hidden"
            v-if="!hasPayment"
            style=""
          >
            <div class="slide-container">
              <div>
                <p class="titleCard" style="color: white">
                  Realiza el pago de tu inscripción
                </p>
              </div>
              <div
                style="display: flex; justify-content: center; margin-top: 20px"
              >
                <p class="descriptionCard" style="color: white">
                  Ya completaste tu registro, ahora realiza el pago de tu
                  inscripción para ser parte de FONCABSA.
                </p>
              </div>
              <div style="display: table; height: 100%">
                <div style="display: table-cell; vertical-align: bottom">
                  <input
                    type="button"
                    class="button_popups"
                    value="Métodos de Pago"
                    v-on:click="
                      navigate('/cuenta/pago-parte-social/elegir-forma-pago')
                    "
                  />
                </div>
              </div>
              <div
                style="justify-content: center; display: flex; margin-top: 10px"
              >
                <input
                  type="button"
                  class="button_popups"
                  v-on:click="
                    navigate('/cuenta/pago-parte-social/upload-voucher')
                  "
                  value="Cargar Comprobante"
                />
              </div>
            </div>
          </div>

          <div
            class="mySlides"
            v-if="hasPayment && admissionStatus == 'aceptada'"
          >
            <div class="slide-container">
              <div>
                <p class="titleCard" style="color: white">Referir a un amigo</p>
              </div>
              <div>
                <p
                  class="descriptionCard"
                  style="margin-top: 20px; color: white"
                >
                  Invita a tus contactos a formar parte en FONCABSA
                </p>
              </div>
              <div style="margin-top: 30px">
                <div style="">
                  <input
                    type="button"
                    class="button_popups"
                    value="Referir Persona"
                    v-on:click="navigate('/cuenta/referidos/referir-persona')"
                  />
                </div>
              </div>
            </div>
          </div>
          <a v-on:click="plusSlides(-1)" class="container-prev-next" style="">
            <a class="prev">❮</a>
          </a>
          <a
            v-on:click="plusSlides(1)"
            class="container-prev-next"
            style="right: 0"
          >
            <a class="next">❯</a>
          </a>
        </div>

        <div class="dot-container">
          <span
            v-for="(item, i) in getNumberCards()"
            :key="i"
            class="dot"
            v-on:click="currentSlide(i + 1)"
          ></span>
        </div>
      </div>
    </div> -->
  </div>
  <!--End Admission Request Pending-->

  <!--Admission Request New-->
  <div
    v-if="
      admissionStatus != 'pendiente_de_revision' &&
      admissionStatus != 'en_revision' &&
      admissionStatus != 'aceptada' &&
      admissionStatus != null
    "
    style="padding: 20px"
  >
    <div class="">
      <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
        <center>
          <p
            class="lg:text-2xl xl:text-5xl title"
            style="font-weight: bold; margin-top: 10px"
          >
            Bienvenido a FONCABSA
          </p>
        </center>
      </div>
      <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
        <center>
          <p
            class="md:text-xl lg:text-1xl lg:text-2xl xl:text-4xl"
            style="color: #6e6e6e; font-size: 16px"
          >
            Para ser socio FONCABSA es necesario que completes tu registro
          </p>
        </center>
      </div>
      <div
        class="md:grid md:grid-cols-4 grid grid-cols-1 gap-10 xl:text-1xl"
        style="text-align: center; margin-top: 20px"
      >
        <div style="text-align: center">
          <img
            src="../../assets/Cuenta/principal/ico1.svg"
            class="img-responsive stepImages"
            style="margin: 0 auto"
          />
          <br />
          <p
            class="label_steps subTitle"
            style="color: #6e6e6e; font-size: 15px"
          >
            Entre a la secci&oacute;n de "Mis datos" en el men&uacute; y elija
            la opci&oacute;n "Completa tu registro".
          </p>
        </div>
        <div>
          <img
            src="../../assets/Cuenta/principal/ico2.svg"
            class="img-responsive stepImages"
            style="margin: 0 auto"
          />
          <br />
          <p
            class="label_steps subTitle"
            style="color: #6e6e6e; font-size: 15px"
          >
            Acepte ser geolocalizado y realice su verificaci&oacute;n de
            identidad (tener a la mano o escaneado su INE/IFE o pasaporte).
          </p>
        </div>
        <div>
          <img
            src="../../assets/Cuenta/principal/ico3.svg"
            class="img-responsive stepImages"
            style="margin: 0 auto"
          />
          <br />
          <p
            class="label_steps subTitle"
            style="color: #6e6e6e; font-size: 15px"
          >
            Complete sus datos personales y valide su n&uacute;mero de celular.
          </p>
        </div>
        <div>
          <img
            src="../../assets/Cuenta/principal/ico4.svg"
            class="img-responsive stepImages"
            style="margin: 0 auto"
          />
          <br />
          <p
            class="label_steps subTitle"
            style="color: #6e6e6e; font-size: 15px"
          >
            Elija el importe que desea ahorrar y el plazo.
          </p>
        </div>
        <div>
          <img
            src="../../assets/Cuenta/principal/ico5.svg"
            class="img-responsive stepImages"
            style="margin: 0 auto"
          />
          <br />
          <p
            class="label_steps subTitle"
            style="color: #6e6e6e; font-size: 15px"
          >
            Elija sus beneficiarios.
          </p>
        </div>
        <div>
          <img
            src="../../assets/Cuenta/principal/ico6.svg"
            class="img-responsive stepImages"
            style="margin: 0 auto"
          />
          <br />
          <p
            class="label_steps subTitle"
            style="color: #6e6e6e; font-size: 15px"
          >
            Cargue sus documentos: CURP, Comprobante de domicilio, Comprobante
            de ingreso (últimos 3 meses), Estado de cuenta y RFC
          </p>
        </div>
        <div>
          <img
            src="../../assets/Cuenta/principal/ico7.svg"
            class="img-responsive stepImages"
            style="margin: 0 auto"
          />
          <br />
          <p
            class="label_steps subTitle"
            style="color: #6e6e6e; font-size: 15px"
          >
            Acepte el aviso de privacidad y el autorizo de descuento para
            generar el documento.
          </p>
        </div>
        <div>
          <img
            src="../../assets/Cuenta/principal/ico8.svg"
            class="img-responsive stepImages"
            style="margin: 0 auto"
          />
          <br />
          <p
            class="label_steps subTitle"
            style="color: #6e6e6e; font-size: 15px"
          >
            Reciba el autorizo de descuento en su correo, entre al enlace y
            f&iacute;rmelo para concluir el proceso.
          </p>
        </div>
      </div>
      <br />
      <br />
    </div>
  </div>
  <!--End Admission Request New-->
</template>

<script>

import { ref, onMounted, onBeforeMount, getCurrentInstance } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";

import Saving from "@/classes/Saving.js";
import Stripe from "@/classes/Stripe.js";
import productsCards from "@/classes/productsCards.js" 
import Loans from "@/classes/Loans.js";

import { getAdmissionRequestOrCreate, getMissingData, createMissingDataArray } from "@/api/user";
import { getTokenDecoden, getUserInfo } from "@/helpers/tokenauth.js";

import Loading from "@/components/Loading/VueLoading.vue";
import SimpleCard from "@/components/Forms/Cards/SimpleCard.vue";
import MessageSlide from "@/components/Modal/MessageSlide";

import("@/assets/css/stylesheet.css");
import Swal from "sweetalert2";
import slide1Image from "@/assets/img_PantallaPrincipal.png";
import slide2ImagePendingReview from "@/assets/Cuenta/welcome.png";

import { get_information_section_benefit, get_benefits_by_client_id, create_array_structure } from "@/helpers/clientBenefits.js"

export default {
  name: "PaginaPrincipal",
  setup() {
    let store = useStore();
    let adsList = ref([]);

    let app = getCurrentInstance();
    let hasBenefitsAvailables = ref([]);
    let loanClassObject = ref(null);

    const router = useRouter();
    const htmlWithPolicy = ref(`
      <div class='leading-3 md:text-center flex flex-col text-right md:leading-tight md:mt-0'  >
        <div class="-mt-2 mb-5 md:my-0">
          <p class='md:text-xl font-semibold main-text'>[currency_1]</p>
          <small class='md:text-md font-light text-gray-400 card-description'>[text_currency_1]</small>
        </div>
       <div>
          <p class='md:text-xl font-semibold main-text'>[currency_2]</p>
          <small class='md:text-md font-light text-gray-400 card-description'>[text_currency_2]</small>
       </div>
      </div>`);
    
    const htmlWithoutPolicy = ref(`
      <router-link to="/cuenta/questions" class="button-contract-policy block w-full md:mr-2 bg-mustardDark p-2 rounded text-white text-center" >
        Contratar Beneficio
      </router-link>`);

    let simpleCardArray = ref([]);

    let messageSlideArray = ref([]);

    let personId = ref(null);
    let admissionStatus = ref(null);
    let admissionRequestId = ref(0);
    let slideIndex = ref(1);
    let isLoading = ref(true);
    let objectClassStripe = ref(null);
    let hasPayment = ref(null);
    let clientName = ref("");
    let totalSavings = ref(0);
    let savingObject = ref(null);
    let clientId = ref(null);
    let reRender = ref(false);
    let productsCardsClass = ref( new productsCards() );
    let organizations = ref([]);
    let rfc = ref(''); 

    onBeforeMount(async () => {
      isLoading.value = true;
      app = app.appContext.config.globalProperties;
      loadInfo();
      
      await getProductsCards()
      
      replaceDataHtmlDefault();
      savingObject.value = new Saving();
      loanClassObject.value = new Loans();

      await getAdmissionRequest();
      
      if(admissionStatus.value == 'aceptada'){
        const client_active_benefits = await getBenefitByClientId();
        render_additional_information(client_active_benefits);
      }

      await isALoanCandidate();
      
      if(organizations.value.includes("sinaptissste")){
        await generateAds();
      }
      
      isLoading.value = false;
    });

    onMounted(async () => {
      objectClassStripe.value = new Stripe();
      isLoading.value = false;
    });

    function getToken(){
      return sessionStorage.getItem('login');
    }
    
    async function isALoanCandidate (){
      let isCandidate = await searchLoanCandidate();
      if(isCandidate.status && isCandidate.data != null && isCandidate.data != ''){
        adsList.value = adsList.value.concat({ module_name: '', module_content: '', module_key: '', message:  '<p>¡Ya puedes solicitar un Préstamo! <br>Ingresa <strong>Aquí</strong> para ver los requisitos </p>',  route:'/cuenta/credito'});
        store.dispatch("setIsCandidate", {value: true});
        return;
      }

      store.dispatch("setIsCandidate", {value: false});
    }

    async function searchLoanCandidate(){
      let resp = await loanClassObject.value.searchLoanCandidate(getToken(), clientId.value).then(resp => {
        return {status: true, message: resp.data.response.message, data: resp.data.response.result};
      }).catch(err => { return { status: false, message: err.response.data.response.message, data: null } });

      return resp;
    }

    async function generateAds(){
      let newAdsList = [];
      let missingData = await getMissingClientData();

      if(missingData != null){
        let adsMissingData = await createMissingDataArray(missingData);
        if(adsMissingData != null){
          adsList.value = newAdsList.concat(adsMissingData);
          store.dispatch("setCountMissingDataAction", {value: adsList.value});
        }
      }
    }
    
    async function getMissingClientData(){
      let token = sessionStorage.getItem("login");
      return await getMissingData(token, clientId.value, "new_client").then(resp => (!resp.data.response.has_error)? resp.data.response.result: null).catch(err => null);
    }

    function navigate(route) {
      router.push({ path: route });
    }

    async function getBenefitByClientId(){
      return await get_benefits_by_client_id(clientId.value).then(resp => create_array_structure(resp)).catch(err =>  null);
    } 

    function loadInfo() {
      var auth = getTokenDecoden();
      let authInfo = getUserInfo();

      organizations.value = auth.obj.organization_list

      if (auth.obj.role == "member" || auth.obj.role == "cabsa_member") {
        personId.value = auth.obj.person["id"];
        clientName.value = app.$filters.formatCamelize(authInfo.short_name);
        totalSavings.value = 0;
        clientId.value = (typeof auth.obj.person.client_id != 'undefined')? auth.obj.person.client_id: 0;
        rfc.value = auth.obj.person.rfc;
      } else {
        personId.value = auth.obj.personnel_info["id"];
        clientName.value = "";
      }
    }

    async function getAdmissionRequest() {
      let token = sessionStorage.getItem("login");
      let person_id = personId.value;
      isLoading.value = true;
      await getAdmissionRequestOrCreate(token, person_id)
        .then((response) => {
          if (response.data.response.admission_request != null) {
            admissionStatus.value =
              response.data.response.admission_request.status;
            admissionRequestId.value =
              response.data.response.admission_request.id;

            sessionStorage.setItem(
              `admissionRequestId`,
              admissionRequestId.value
            );
          } else {
            admissionStatus.value = "";
          }
          getPayment();
        })
        .catch((error) => {
          isLoading.value = false;
          Swal.fire({
            title: "Aviso",
            text: "Se presentó un error al consultar las solicitudes",
            icon: "error",
            confirmButtonColor: "#FEB72B",
          });

        });
    }

    function getPayment() {
      
      return objectClassStripe.value
        .hasPayment(sessionStorage.getItem("login"), admissionRequestId.value)
        .then((response) => {
          hasPayment.value = response.data.response.has_payment;
          
          let pagesList = [];
          if(hasPayment.value == false && organizations.value.includes("sinaptissste")){
            pagesList.push(addRequestPayment());
          }else{
            if(hasPayment.value == false && admissionStatus.value == 'aceptada'){
              pagesList.push(addRequestPayment());
            }
          }
          
          if(["en_revision","pendiente_de_revision"].includes(admissionStatus.value)){
            pagesList.push(requestPendingReview());
          }
          
          if(admissionStatus.value == 'aceptada'){
            pagesList.push(addInviteMember());
          }

          messageSlideArray.value = pagesList;
          reRender.value = !reRender.value;
          isLoading.value = false;
        })
        .catch((error) => {

          let pagesList = [];
          hasPayment.value = false;
          if(hasPayment.value == false && organizations.value.includes("sinaptissste")){
            pagesList.push(addRequestPayment());
          }else{
            if(hasPayment.value == false && admissionStatus.value == 'aceptada'){
              pagesList.push(addRequestPayment());
            }
          }
          
          if(["en_revision","pendiente_de_revision"].includes(admissionStatus.value)){
            pagesList.push(requestPendingReview());
          }
          
          if(admissionStatus.value == 'aceptada'){
            pagesList.push(addInviteMember());
          }

          messageSlideArray.value = pagesList;
          reRender.value = !reRender.value;
          isLoading.value = false;
        });
    }

    function addRequestPayment(){
      return {
        status: true,
        message:
          "Realiza el pago de inscripción asistida para ser parte de FONCABSA",
        buttons: [
          {
            externalLink: false,
            link: "/cuenta/pago-parte-social/elegir-forma-pago",
            text: "Métodos de pago",
          },
          {
            externalLink: false,
            link: "/cuenta/pago-parte-social/upload-voucher",
            text: "Cargar comprobante",
          },
        ],

        images: {
          mobile: slide1Image,
          desktop: slide1Image,
        },
      };
    }

    function requestPendingReview(){
      return {
        status: true,
        message:
          "Solicitud en revisión, \n\n\n\n pronto será notificado cuando su solicitud sea aceptada o rechazada",
        buttons: [],

        images: {
          mobile: slide2ImagePendingReview,
          desktop: slide2ImagePendingReview,
        },
      };
    }

    function addInviteMember(){
      return {
        status: false,
        message: "Invita a tus contactos a formar parte de FONCABSA",
        buttons: [
          {
            externalLink: false,
            link: "/cuenta/referidos/referir-persona",
            text: "Referir persona",
          },
        ],
        images: {
          mobile: slide1Image,
          desktop: slide1Image,
        },
      };
    }

    function plusSlides(n) {
      showSlides((slideIndex.value += n));
    }

    function currentSlide(n) {
      showSlides((slideIndex.value = n));
    }

    function showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      if (n > slides.length) {
        slideIndex.value = 1;
      }
      if (n < 1) {
        slideIndex.value = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[slideIndex.value - 1].style.display = "flex";
      dots[slideIndex.value - 1].className += " active";
    }

    function getNumberCards() {
      let number = document.querySelectorAll(
        ".slideshow-container .mySlides"
      ).length;
      return number;
    }

    function replaceDataHtmlDefault(){
      htmlWithPolicy.value = htmlWithPolicy.value.replace('[currency_1]','$200.00')
      htmlWithPolicy.value = htmlWithPolicy.value.replace('[currency_2]','$1,000,000.00')
      htmlWithPolicy.value = htmlWithPolicy.value.replace('[text_currency_1]','Dcto. Mensual')
      htmlWithPolicy.value = htmlWithPolicy.value.replace('[text_currency_2]','Suma Asegurada')

      simpleCardArray.value[2].mainText = htmlWithoutPolicy.value;/* 
      simpleCardArray.value[3].mainText = htmlWithPolicy.value; */
    }

    async function render_additional_information(response_products_benefits){
    
      if(!(response_products_benefits !== null)){
        return null;
      }

      const keyOfBenefits = response_products_benefits.map(benefit => {
        return benefit.node_key;
      });
      
      for (const benefits of  keyOfBenefits){
        simpleCardArray.value = await  get_information_section_benefit(simpleCardArray.value, benefits, response_products_benefits);
      }

      let benefitsAvailable = simpleCardArray.value.filter(element => element.status == true);
      hasBenefitsAvailables.value = (benefitsAvailable.length>0)? true: false;
    }

    async function getProductsCards(){
      const _response = await productsCardsClass.value.get_products_cards("cardsProducts/cards_products.json");
      simpleCardArray.value =  _response;
    }

    return {
      router,
      simpleCardArray,
      messageSlideArray,
      plusSlides,
      currentSlide,
      getNumberCards,
      admissionStatus,
      clientName,
      navigate,
      isLoading,
      reRender,
      hasBenefitsAvailables,
      adsList
    };
  },
  components: {
    Loading,
    SimpleCard,
    MessageSlide
  }
};
</script>

<style scoped>
.button_popups {
  border-radius: 5px;
  background-color: #feb72b;
  color: #fff;
  font-weight: bold;
  width: 250px;
  height: 40px;
  padding-top: 0%;
  font-size: 16px;
}
.circled-number {
  color: #666;
  border: 17px solid #f4f4f4;
  border-radius: 50%;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25em;
  height: 25em;
}

#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.stepImages {
  width: 23%;
}
.slide-container {
  /* justify-content: space-between; */
  flex-direction: column;
  height: 300px;
  display: flex;
  background-color: #810042;
  border: 1px solid #810042;
  border-radius: 4px;
  padding: 10px;
  width: 280px;
}
.slideshow-container {
  position: relative;
  left: 0;
  width: 100%;
  background: #f4f4f4;
}

/* Slides */
.mySlides {
  justify-content: center;
  padding: 80px;
  text-align: center;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  text-decoration: none !important;
}

/* Position the "next button" to the right */
.next {
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
/* .prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
  color: white;
} */

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 20px;
  background: #ddd;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */
.active,
.dot:hover {
  background-color: #717171;
}

/* Add an italic font style to all quotes */
q {
  font-style: italic;
}

/* Add a blue color to the author */
.author {
  color: cornflowerblue;
}

.container-prev-next {
  cursor: pointer;
  border-style: outset;
  position: absolute;
  top: 0%;
  width: auto;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  height: 100%;
}

.container-prev-next:hover {
  background-color: #d8d8d8;
}
.titleCard {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.descriptionCard {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

body {
  padding: 0px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}
.hide-scroll {
  overflow: auto;
  /* height: 100px; */
  scrollbar-width: none; /*Firefox*/
}

/*Webkit*/
.hide-scroll::-webkit-scrollbar {
  width: 0;
}

.primary-text {
  font-family: RobotoRegular;
}

small {
  font-family: "RobotoRegular";
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #857b70;
}
.button-contract-policy{
    font-family: "roboto-regular";
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
}

.bg-container {
  padding: 0px !important;
  --tw-bg-opacity: 1;
  background-color: initial;
}
.bg-gray-container {
padding: 0px !important;
--tw-bg-opacity: 1;
background-color: rgba(249, 250, 251, var(--tw-bg-opacity))!important;
}
.rectangle {
  position: relative;
}
</style>

<style>
.carousel__pagination-item > button {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: #E5A800 !important;
}
</style>