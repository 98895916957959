import api from '@/api/api';
import Swal from "sweetalert2";

export default class productsCards {

    async get_products_cards(urlFile){

        const resource = `/json/${urlFile}`;

        try {
            const _response = await api.httpGet(resource, { baseURL: 'hostLocalWeb' }, {});
            return _response.data
        } catch (ex) {
            console.log(ex)
            Swal.fire({
                title: "Aviso",
                html: "Ocurrió un problema al obtener las tarjetas de documentos.",
                icon: "info",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
            });
            return false;
        }
    }
}