const host = process.env.VUE_APP_CCAPI;
const business = process.env.VUE_APP_BUSINESS_FONCABSA
 
import api from '@/api/api';

export default class Loans {
    data = {
        "token_auth":"",
        "foncabsa_client_id" : 0,
        "personal_info": {
            "identification_type":"",
            "identification_number":"",
            "residence_type":"",
            "recsidence_date":"",
            "education_level":"",
            "marital_status":" ",
            "regime":"",
            "spouse": {
              "rfc": "",
              "name": "",
              "gender": "",
              "middle_name": "",
              "maternal_name": "",
              "paternal_name": "",
              "birtday":""
            },
            "contact_info":[
                {
                  "value": "",
                  "extension": "",
                  "contact_type": ""
                },
                {
                  "value": "",
                  "extension": "",
                  "contact_type": ""
                },
                {
                  "value": "",
                  "status": "",
                  "extension": "",
                  "contact_type": ""
                }
            ],
            "work_info": {
              "status": "",
              "category": "",
              "occupation": 0,
              "supervisor": "",
              "active_since": "",
              "business_name": "",
              "laboral_status": "",
              "employee_number": [
                ""
              ],
              "principal_occupation": "",
              "has_had_public_positions":false,
              "any_relative_has_had_public_positions": false
            },
            "workplace": {
              "name": "",
              "city": "",
              "state": "",
              "street": "",
              "suburb": "",
              "status": "",
              "postal_code": "",
              "country": "",
              "center_key": "",
              "ext_number": "",
              "int_number": "",
              "municipality": "",
              "adress": {
                "city": "",
                "state": "",
                "status": "",
                "street": "",
                "suburb": "",
                "country": "",
                "latitude": "",
                "longitude": "",
                "ext_number": "",
                "int_number": "",
                "postal_code": "",
                "municipality": "",
                "residence_start": "",
                "residence_status": "",
                "between_street_one": "",
                "between_street_two": ""
              }
            },
            "salary_info": {
              "liquidity": 0,
              "monthly_income": 0,
              "income_frecuency": ""
            }
          },
        "documents_list": [],
        "credit_request_info": {
          "amount":"",
          "term":"",
          "payment_periodicity":"",
          "credit_destination":"",
          "payment_method":""
        },
        "credit_owner_info":{
          "credit_benefit_statement":"",
          "rfc":"",
          "name":"",
          "middle_name":"",
          "paternal_name":"",
          "maternal_name":"",
          "gender":"",
          "nationality":"",
          "country":"",
          "state":"",
          "city":"",
          "curp":"",
          "email":"",
          "cellphone":"",
          "phone":"",
          "has_had_public_positions":false,
          "any_relative_has_had_public_positions":false
        },
        "credit_provider_info": {
          "credit_benefit_statement":"",
          "rfc":"",
          "name":"",
          "middle_name":"",
          "paternal_name":"",
          "maternal_name":"",
          "gender":"",
          "nationality":"",
          "country":"",
          "state":"",
          "city":"",
          "curp":"",
          "email":"",
          "cellphone":"",
          "phone":"",
          "has_had_public_positions":false,
          "any_relative_has_had_public_positions":false
        }
    };

    searchLoanCandidate = async (token, clientId) => {
        const resource = `/${business}/loans/search_loan_candidate`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { 
            token_auth: token,
            client_id: clientId
        };

        return await api.httpGet(API_URL, config, params).catch(err => { return err });
    }

    registerCreditRequest = async (token, foncabsaClientId, personalInfo) => {
        const resource = `/${business}/loan_requests/register`;
        const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { 
            token_auth: token,
            foncabsa_client_id: foncabsaClientId,
            personal_info: personalInfo
        };

        return await api.httpPost(API_URL, config, params).catch(err => { return err });
    }

    getActiveLoansCandidates = async (token) => {
      const resource = `/${business}/loans_candidates/active_loan_candidates`;
      const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { 
            token_auth: token
        };

        return await api.httpGet(API_URL, config, params).catch(err => { return err });
    }

    updateCreditLimit = async (token, clientId, amount) => {
      const resource = `/${business}/client/update_credit_limit`;
      const API_URL = `${host}${resource}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = { 
            token_auth: token,
            client_id: clientId,
            amount: amount,
        };

        return await api.httpPost(API_URL, config, params).catch(err => { return err });
    }

}